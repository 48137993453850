import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';

import { BookingLayout } from 'containers';
import { BookingHeader } from 'layouts';
import {
  BookingSummary,
  BookHaka,
  SummaryList,
  BookingToolbar,
  Notice,
} from 'components';
import { PAGES } from 'config';
import { mapStateToProps } from 'utilities';

class BookingsReviewPage extends PureComponent {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
  }

  /**
   * Handle next click
   * @param e
   */
  handleNext(e) {
    e.preventDefault();
    const { booking } = this.props;

    if (booking.currentBooking && booking.total === booking.total_paid && booking.currentBooking.balance_remaining <= 0) {
      navigate(`${PAGES.BOOKINGS_THANK_YOU.PATH}?bookingId=${booking.currentBooking.id}&tour=${booking.currentBooking.tour_slug}`);
    } else {
      navigate(`${PAGES.BOOKINGS_PAYMENT.PATH}?bookingId=${booking.currentBooking.id}&tour=${booking.currentBooking.tour_slug}`);
    }
  }

  render() {
    const { booking, user } = this.props;
    return (
      <BookingLayout page="bookings-review">
        <div className="l-two-col l-two-col--large">
          <BookingHeader
            title="Your Tour"
            subTitle="Check the details of your tour."
            hasBackButton={1}
          />
          <div>
            {
              booking.currentBooking
                ? (
                  <>
                    {
                      booking.currentBooking.locked
                        ? (
                          <Notice message="locked" />
                        ) : null
                    }
                    <BookingSummary
                      booking={booking.currentBooking}
                      accommodations={booking.accommodations}
                      showEdit={!booking.currentBooking.locked}
                    />
                    <div className="u-card c-booking-summary">
                      <div className="c-booking-summary__title">
                        <h4 className="c-heading c-heading--h3">Your Details</h4>
                        {
                          !booking.currentBooking.locked
                            ? (
                              <Link
                                to={`${PAGES.BOOKINGS_DETAILS.PATH}?bookingId=${booking.currentBooking.id}&tour=${booking.currentBooking.tour_slug}`}
                                className="c-booking-summary__edit-btn"
                              >
                                Edit
                              </Link>
                            ) : null
                        }
                      </div>
                      {user.session.is_guest
                        ? (
                          <SummaryList items={[
                            {
                              id: 'phone',
                              title: 'Phone',
                              content: booking.currentBooking.phone,
                            },
                            {
                              id: 'ref-code',
                              title: 'Reference code',
                              content: booking.currentBooking.reference_code,
                            },
                          ]}
                          />
                        ) : (
                          <SummaryList items={[
                            {
                              id: 'email',
                              title: 'Email',
                              content: booking.currentBooking.user_email,
                            },
                            {
                              id: 'phone',
                              title: 'Phone',
                              content: booking.currentBooking.phone,
                            },
                            {
                              id: 'ref-code',
                              title: 'Reference code',
                              content: booking.currentBooking.reference_code,
                            },
                          ]}
                          />
                        )}
                    </div>
                  </>
                ) : <h4>Loading...</h4>
            }
          </div>
          <div>
            <BookHaka />
          </div>
        </div>
        <BookingToolbar>
          <button onClick={this.handleNext} type="submit" className="c-button c-button--primary c-button--wide">Next</button>
        </BookingToolbar>
      </BookingLayout>
    );
  }
}

BookingsReviewPage.propTypes = {
  booking: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(BookingsReviewPage);
